import axios from "axios";
import Cookies from "universal-cookie";
const Backoffice = () => {
    const cookies = new Cookies();
    const JWT_TOKEN = cookies.get('JWT_TOKEN')
    async function updateAdaBoxesScore () {
        try {
            await axios.get(
                process.env.REACT_APP_API_URL + 'adaBox/updateScore',
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${JWT_TOKEN}`
                    },
                }
            )
        } catch (error) {
            console.error('Erreur : ', error);
        }
    }

    return (
        <>
            <p className='updateScoreButton' onClick={updateAdaBoxesScore}>UPDATE</p>
        </>
    )
}

export default Backoffice;