import Header from "./Components/Header";
import Networks from "./Components/Networks"
import Aside from "./Components/Aside";
import Navbar from "./Components/Navbar";

const Home = () => {

    function scrollToBottom() {
        const scrollDuration = 1000; // Durée de l'animation en millisecondes
        const scrollStep = -window.scrollY / (scrollDuration / 15); // Distance à faire défiler à chaque étape
        let scrollInterval: any;

        function step() {
            if (window.scrollY < window.innerHeight) {
                window.scrollBy(0, scrollStep); // Faites défiler d'un pas
            } else {
                clearInterval(scrollInterval); // Arrêtez l'animation une fois en bas
            }
        }

        scrollInterval = setInterval(step, 15); // Appel de la fonction "step" toutes les 15 millisecondes
    }

// Appelez la fonction pour faire défiler automatiquement vers le bas immédiatement
    scrollToBottom();



    return (
        <>
            <Navbar />
            <Header />
            <main>
                <div className="relative z-10 w-full overflow-x-clip">
                    <Networks />

                </div>
            </main>
            {/*<Aside />*/}
        </>
    );
}

export default Home;