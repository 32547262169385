import { useScroll, useTransform, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import {AnimatedText} from "./AnimatedText";

const Header = () => {
    const targetRef = useRef<HTMLDivElement | null>(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["end end", "end start"],
    });
    const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
    const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.8]);
    const position = useTransform(scrollYProgress, (pos) =>
        pos >= 1 ? "relative" : "fixed"
    );

    useEffect(() => {
        const updateMousePosition = (ev: MouseEvent) => {
            if (!targetRef.current) return;
            const { clientX, clientY } = ev;
            targetRef.current.style.setProperty("--x", `${clientX}px`);
            targetRef.current.style.setProperty("--y", `${clientY}px`);
        };

        window.addEventListener("mousemove", updateMousePosition);

        return () => {
            window.removeEventListener("mousemove", updateMousePosition);
        };
    }, []);

    return (
        <>
            <motion.section
                style={{ opacity }}
                ref={targetRef}
                className="header relative h-screen py-16 text-white before:pointer-events-none before:fixed before:inset-0 before:z-0 before:bg-[radial-gradient(circle_farthest-side_at_var(--x,_100px)_var(--y,_100px),_var(--color-secondary)_0%,_transparent_100%)] before:opacity-40"
            >
                <motion.div
                    style={{ position, scale, x: "-50%" }}
                    className="fixed left-1/2 z-10 flex flex-col items-center"
                >
                    <h1 className="home-title mb-3 text-center font-heading text-xl leading-[1]">
                        Aspig
                    </h1>
                    <h2 className="mb-12 text-center font-heading text-md">
                        French Streamer
                    </h2>

                    <img className="h-[300px]" src="https://i.imgur.com/ta6QEG8.png" alt="aspig-logo"/>
                </motion.div>

                <AnimatedText className="p-scroll transform rotate-270" text={"SCROLL"} repeatDelay={2000} waitingDelay={4000} />
            </motion.section>
        </>
    );
};

export default Header;