import {motion, useAnimation, useInView, useScroll, useTransform} from "framer-motion";
import {AnimatedText} from "./AnimatedText";
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";
import {useState} from "react";

const Navbar = () => {
    const navigate = useNavigate()
    const cookies = new Cookies();
    const [isLogged, setIsLogged] = useState(cookies.get('discordGlobalName') !== undefined);
    console.log(isLogged)
    console.log(cookies.get('discordGlobalName'))
    const logosData = [
        {
            name: "twitch",
            path: "https://i.imgur.com/RANJ45d.png"
        },
        {
            name: "youtube",
            path: "https://i.imgur.com/KvSPAoG.png"
        },
        {
            name: "x",
            path: "https://i.imgur.com/7Wbt7gl.jpeg"
        },
        {
            name: 'discord',
            path: "https://i.imgur.com/LglJZQG.png"
        }
    ]

    //TODO créer module
    const navigateBis = (name: any) => {
        const urls = [
            {
                name: 'twitch',
                path: 'https://www.twitch.tv/aspig'
            },
            {
                name: 'youtube',
                path: 'https://www.youtube.com/@AsPigTv'
            },
            {
                name: 'x',
                path: 'https://twitter.com/AsPigTV'
            },
            {
                name: 'discord',
                path: 'https://discord.gg/9nKbxnzRep'
            },
            {
                name: 'ada',
                path: 'https://ada.aspig.gg/'
            },
            {
                name:"pull'em'all",
                path: 'https://gottapull.aspig.gg/'
            }
        ]
        const url = urls.find(item => item.name === name);
        if (url) {
            window.open(url.path, '_blank')
        }
    }
    const discordUrl = process.env.REACT_APP_DISCORD_AUTHENTICATE_URL;

    const logos = logosData.map((logo, index) => (
        <motion.div
            whileHover={{
                scale: 1.4,
                cursor: "pointer"
            }}
        >
            <img onClick={() => navigateBis(logo.name)} className="navbar-logo" src={logo.path} alt={logo.name} />
        </motion.div>
    ));

    const username = cookies.get('discordGlobalName');
    const logout = () => {
        cookies.remove('JWT_TOKEN', { domain: process.env.REACT_APP_DOMAIN, path: '/'})
        cookies.remove('discordGlobalName', { domain: process.env.REACT_APP_DOMAIN, path: '/'});
        setIsLogged(false);
    }

    return (
        <>
            <div className="navbar" >
                <img className="navbar-logo-aspig" src="https://i.imgur.com/ta6QEG8.png" alt="aspig-logo"/>

                <motion.div
                    whileHover={{
                        scale: 1.15,
                        cursor: "pointer"
                    }}
                >
                    <span onClick={() => window.location.href = process.env.REACT_APP_ADA_URL ?? ''} className="navbar-logo">Ada</span>
                </motion.div>

                <motion.div
                    whileHover={{
                        scale: 1.15,
                        cursor: "pointer"
                    }}
                >
                    <span onClick={() => window.location.href = process.env.REACT_APP_PULLEMALL_URL ?? ''} className="navbar-logo">Pull'Em'All</span>
                </motion.div>

                {logos}

                { isLogged && (
                    <div className="navbar-login-container">
                        <span>{username}</span>
                        <motion.img
                            whileHover={{
                                scale: 1.4,
                                cursor: "pointer"
                        }}
                            onClick={() => logout()}
                            className="navbar-logout-logo" src="https://i.imgur.com/ClrPOlO.png" alt="logout-logo"
                        />
                    </div>
                ) }
                { !isLogged && (
                    <div className="navbar-login-container">
                        <motion.div
                            whileHover={{
                                scale: 1.15,
                                cursor: "pointer"
                        }}
                        >
                            <span onClick={() => window.location.href = discordUrl ?? ''}>Login</span>
                        </motion.div>
                    </div>
                ) }
            </div>
        </>
    )
}

export default Navbar;