import { motion, useInView, useAnimation, Variant } from "framer-motion";
import { useEffect, useRef } from "react";

type AnimatedTextProps = {
    text: string | string[];
    el?: keyof JSX.IntrinsicElements;
    className?: string;
    once?: boolean;
    repeatDelay?: number;
    waitingDelay?: number;
    animation?: {
        hidden: Variant;
        visible: Variant;
    };
};

const defaultAnimations = {
    hidden: {
        opacity: 0,
        y: -10,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.2,
        },
    },
};

export const AnimatedText = (
    {
        text,
        el: Wrapper = "p",
        className,
        once,
        repeatDelay,
        waitingDelay,
        animation = defaultAnimations,
    }: AnimatedTextProps
) => {
    const controls = useAnimation();
    const textArray = Array.isArray(text) ? text : [text];
    const ref = useRef(null);
    const isInView = useInView(ref, { amount: 0.5, once });

    useEffect(() => {
        let interval: NodeJS.Timeout;
        const show = () => {
            controls.start("visible");
        };
        const hide = () => {
            controls.start("hidden");
        };
        if (isInView) {
            show(); // Affiche l'élément au chargement
            if (repeatDelay ) {
                interval = setInterval(() => {
                    hide(); // Cache l'élément
                    setTimeout(show, waitingDelay); // Affiche l'élément après un court délai
                }, repeatDelay + repeatDelay); // Ajoute la durée d'affichage (4 secondes) au délai
            }
        } else {
            hide(); // Cache l'élément si il n'est pas dans la vue
        }
        return () => {
            clearInterval(interval); // Nettoie l'intervalle à la désactivation du composant
        };
    }, [isInView, repeatDelay, waitingDelay]);




    return (
        <Wrapper className={className}>
            <span className="sr-only">{text}</span>
            <motion.span
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={{
                    visible: { transition: { staggerChildren: 0.1 } },
                    hidden: { transition: { staggerChildren: 0.1 } },
                }}
                aria-hidden
            >
                {textArray.map((line, lineIndex) => (
                    <span className="block" key={`${line}-${lineIndex}`}>
                        {line.split(" ").map((word, wordIndex) => (
                            <span className="inline-block" key={`${word}-${wordIndex}`}>
                                {word.split("").map((char, charIndex) => (
                                    <motion.span
                                        key={`${char}-${charIndex}`}
                                        className="inline-block"
                                        variants={animation}
                                    >
                                        {char}
                                    </motion.span>
                                ))}
                                <span className="inline-block">&nbsp;</span>
                            </span>
                        ))}
                    </span>
                ))}
            </motion.span>
        </Wrapper>
    );
};